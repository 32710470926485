import dynamic from 'next/dynamic'
import { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Button, Container, Grid, IconButton, Link, Menu, MenuItem, Tooltip, alpha, useMediaQuery, useTheme, Chip } from "@mui/material"
import { getStore } from "../firebase";

import Hamburger from "hamburger-react";

import { FaFacebookF } from 'react-icons/fa';
import { AccountCircle, Instagram, Twitter } from "@mui/icons-material";
// import { useCollectionData } from 'react-firebase-hooks/firestore'

import Logo from "./Logo";

import { menuConfig } from "../configs/menuConfig";
import { COLORS } from '../theme'
import { signOutFirebase } from "../services/auth";
import { useRouter } from "next/router";
import { routes } from "../routes";
import { getFullName } from '../utils/fullName';
import { useAuth } from "../contexts/AuthProvider";

export default function NavBar() {
  const [open, setOpen] = useState(false);

  const router = useRouter();

  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    router.replace(routes.home);
    signOutFirebase();
  };

  const handleMenuItemClick = (path) => {
    handleClose();
    router.push(path);
  };

  useEffect(() => {
    getStore().then(async ({ db, query, collection, where, getDocs }) => {

      if (user) {
        const q = query(collection(db, 'messages'), where('to', '==', user ? user.email : ''), where('read', '==', false))
        const messages = await getDocs(q);
    
        if (messages.docs.length > 0) {
          setUnreadMessages(messages.docs.length)
        }
      }
    })
  }, [user]);

  const loginButton =  (
    <Box>
      {
        user ? (
          <Grid container alignItems={'center'}>
            <Tooltip title={getFullName(user.firstName, user.lastName)}>
              <IconButton
                size={user.photoURL ? 'small' : 'medium'}
                onClick={handleClick}
                color='secondary'
                aria-label="Account Menu"
              >
                {user.photoURL ? (
                  <Avatar alt={getFullName(user.firstName, user.lastName)} src={user.photoURL} />
                ) : (
                  <AccountCircle />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        ) : isMobile ? (
          <IconButton onClick={() => router.push(routes.login)} color="primary" aria-label="Account Menu">
            <AccountCircle />
          </IconButton>
        ) : (
          <Button
            variant='contained'
            color='primary'
            aria-label="Account Menu"
            endIcon={<AccountCircle />}
            onClick={() => router.push(routes.login)}
          >
            Sign In
          </Button>
        )
      }
      <Menu
        id='account-menu'
        anchorEl={anchorEl}
        keepMounted
        open={anchorEl != null}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={() => handleMenuItemClick(routes.account)}>My Account</MenuItem>
        {user && (
          <MenuItem onClick={() => handleMenuItemClick(routes.personalMatchmaking)}>My Matches</MenuItem>
        )}
        {user && (
          <MenuItem onClick={() => handleMenuItemClick(routes.myMessages)}>My Messages&nbsp;&nbsp;&nbsp; {
            unreadMessages > 0 && (
              <Chip label={unreadMessages} size='small' />
            )
          }</MenuItem>
        )}
        {user && user.isAdmin && (
          <MenuItem onClick={() => handleMenuItemClick(routes.admin)}>Dashboard</MenuItem>
        )}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  )

  return (
    <Grid container sx={{
      position: 'sticky',
      top: 0,
      zIndex: 2,
      fontWeight: theme.typography.fontWeightBold,
      backgroundColor: alpha(theme.palette.common.white, 0.95),
      borderBottom: `1px solid ${theme.palette.divider}`,
    }}>
      <Container>
        <Box py={1.2}>
          <Grid container alignItems='center' wrap='nowrap' justifyContent='space-between'>
            <Box sx={{
              [theme.breakpoints.up('md')]: {
                display: 'none',
              },
              zIndex: 999,
            }}>
              <Hamburger size={24} toggle={setOpen} toggled={open} direction='right' label="hamburger menu" />
            </Box>
            <Box sx={{
              maxWidth: 170,
              '& a': {
                display: 'flex',
              },
              [theme.breakpoints.down('sm')]: {
                maxWidth: 150,
                position: 'relative',
                top: theme.spacing(0.3),
              },
            }}>
              <Logo />
            </Box>
            <Box sx={{
              '& a': {
                textDecoration: 'none',
                color: theme.palette.secondary.main,
                marginRight: theme.spacing(2),
                [theme.breakpoints.down('sm')]: {
                  margin: 0,
                  marginBottom: theme.spacing(2),
                },
                '&:hover': {
                  color: '#000',
                  scale: 1.04,
                },
              },
              [theme.breakpoints.down('md')]: {
                zIndex: 998,
                position: 'fixed',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                height: '100vh',
                overflowY: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: alpha(theme.palette.common.white, 0.95),
                padding: theme.spacing(2),
                fontSize: theme.typography.h5.fontSize,
                opacity: open ? 1 : 0,
                transition: theme.transitions.create(['opacity']),
                pointerEvents: open ? 'all' : 'none',
              },
            }} display='flex' alignItems='center'>
              {
                menuConfig.map((item) => (
                  <Link onClick={() => { setOpen(false) }} href={item.path} key={item.path}>
                    {item.name}
                  </Link>
                ))
              }
              <Grid item>
                <Grid container alignItems='center' spacing={{ xs: 2, md: 0 }}>
                  <Grid item>
                    <Link href='https://www.facebook.com/londondateevents' aria-label="Facebook Truedating Link">
                      <FaFacebookF style={{ fill: COLORS.radicalRed }} size={20} />
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href='https://www.instagram.com/true.dating' aria-label="Instagram Truedaing Link">
                      <Instagram color='primary' />
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href='https://twitter.com/truedating1' aria-label="Twitter Link">
                      <Twitter color='primary' />
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              {
                !isMobile && (
                  loginButton
                )
              }
            </Box>
            {
              isMobile && (
                loginButton
              )
            }
          </Grid>
        </Box>
      </Container>
    </Grid>
  )
}