// eslint-disable-next-line
import { getFirestore } from "firebase/firestore"; 
import { app } from "./app";

export const db = getFirestore(app);

// eslint-disable-next-line
export {
  query,
  where,
  orderBy,
  doc,
  getDoc,
  getDocs,
  collection,
  limit,
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  writeBatch,
  refEqual,
  serverTimestamp,
  onSnapshot, 
  limitToLast
} from "firebase/firestore";