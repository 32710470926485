import { getStore } from "../firebase";

const ADMINS = 'admins';

export const isAdmin = async (email) => {
  if (!email) return;
  const { db, doc, getDoc } = await getStore();
  const userRef = doc(db, ADMINS, email);
  const snapshot = await getDoc(userRef);

  return snapshot.exists();
};

export const getAdmins = async () => {
  const { db, query, collection, getDocs } = await getStore();
  const q = query(collection(db, ADMINS));
  const admins = (await getDocs(q)).docs.map((doc) => doc.id);

  return admins
}
