// eslint-disable-next-line
import { 
  initializeAuth,   
  debugErrorMap,
  indexedDBLocalPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { app } from "./app";

export const auth = initializeAuth(
  app,
  {
    persistence: browserLocalPersistence,
    popupRedirectResolver: undefined,
    errorMap: debugErrorMap 
  }
);

// export const auth = getAuth(app);

// eslint-disable-next-line
export {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signOut,
  sendSignInLinkToEmail,
  setPersistence,
  browserLocalPersistence 
} from "firebase/auth";