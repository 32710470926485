'use client';

import { Box, Button, Typography, Paper } from '@mui/material';
import { useCookies } from 'react-cookie';
import Link from 'next/link';
import { useState, useEffect } from 'react';

export default function CookieConsent() {
  const [cookies, setCookie] = useCookies(['cookieConsent']);
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const [isClient, setIsClient] = useState(false);

  // Detect if we're on the client
  useEffect(() => {
    setIsClient(true);
  }, []);

  // Only render the cookie consent popup on the client side
  if (!isClient || isConsentGiven || cookies.cookieConsent) return null;

  const giveCookieConsent = () => {
    setCookie('cookieConsent', true, {
      path: '/',
      expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
    });
    setIsConsentGiven(true);
  };

  const closeConsentPopup = () => {
    setCookie('cookieConsent', false, {
      path: '/',
      expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
    });
    setIsConsentGiven(true);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: 2,
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          maxWidth: 800,
          padding: 3,
          backgroundColor: '#fff',
          color: 'black',
          borderRadius: 2,
          boxShadow: 3,
          width: '100%',
        }}
      >
        <Typography
          variant='body1'
          sx={{
            flexGrow: 1,
            fontSize: 16,
            lineHeight: 1.5,
            marginRight: 2,
          }}
        >
          <strong>We use cookies!</strong> To improve your experience, we use
          cookies on our website. By continuing to browse the site, you agree to
          our use of cookies. You can read more about our{' '}
          <Link href='/privacy-policy' passHref style={{ color: '#1976d2' }}>
            Privacy Policy
          </Link>
          .
        </Typography>

        <Box>
          <Button
            variant='contained'
            color='primary'
            onClick={giveCookieConsent}
            sx={{ marginRight: 1 }}
          >
            Accept
          </Button>
          {/* Close button as a regular Button */}
          <Button
            variant='outlined'
            onClick={closeConsentPopup}
            color='secondary'
          >
            Close
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
