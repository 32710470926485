/**
 * This file is used to split the firebase modules into their own chunk
 * 
 * Unfortunately this breaks auth persistence, and I've not been able to find
 * a solution yet, so I'm currently reverting to a static import
 * 
 * This results in the firebase code being bundled with the page code,
 * which is suboptimal, but it works
 * 
 * Andru - 2024-06-08
 * 
 */
// disabled dynamic import
// export const getAuth = () => import("./auth")
// export const getStore = () => import("./store")

import * as auth from "./auth"
import * as store from "./store"
import * as storage from "./storage"

export const getAuth = async () => auth;
export const getStore = async () => store;
export const getStorage = async () => storage;