export const routes = {
    home: '/',
    londonBridge: '/london-bridge',
    soho: '/soho',
    hammersmith: '/hammersmith',
    chelsea: '/chelsea',
    farringdon: '/farringdon',
    shoreditch: '/shoreditch',
    clapham: '/clapham',
    brixton: '/brixton',
    about: '/about',
    faq: '/faq',
    events: '/events',
    sitemap: '/sitemap',
    personalMatchmaking: '/matches',
    blog: '/blog',
    contact: '/contact',
    profile: '/profile',
    account: '/account',
    login: '/login',
    signUp: '/sign-up',
    passwordReset: '/reset-password',
    admin: '/admin',
    adminEvents: '/admin/events',
    adminBlogs: '/admin/blogs',
    adminUsers: '/admin/users',
    speeddating: '/london/speed-dating',
    adminSubscribers: '/admin/subscribers',
    adminRefund: '/admin/refund',
    offers: '/admin/offers',
    cache: '/admin/cache',
    messages: '/messages',
    myMessages: '/my-messages',
    matches: '/matches',
    tos: '/terms-of-service',
    membership: '/membership',
    privacyPolicy: '/privacy-policy',
    pastEvents: '/pastEvents',
    thankyou: '/thankyou',
    matureDating: '/uk-mature-dating',
    christianDating: '/christian-dating',
    gayDating: '/gay-dating',
};