import { EMAIL_FOR_SIGN_IN } from '../constants';
import { generateUserDocument } from './user';
import { getAuth } from '../firebase';

export const signIn = async (email, password) => {
  const {auth, signInWithEmailAndPassword} = await getAuth();
  return signInWithEmailAndPassword(auth, email, password);
}

export const signUp = async (userData: { 
  email: string, 
  password: string,
  firstName: string,
  lastName: string,
  gender: string
}) => {
  const { email, password, ...userInfo } = userData;
  const {auth, createUserWithEmailAndPassword} = await getAuth();
  return createUserWithEmailAndPassword(auth, email, password)
    .then((res) => {
      return {
        data: generateUserDocument({ ...userInfo, email, uid: res.user.uid }),
      }
    }).catch((_err) => {
      return {message: "The email address is already in use by another account", data: null}
    });
}

export const signOutFirebase = async () => {
  const { auth, signOut } = await getAuth();
  signOut(auth)
}

// export const socialMediaSignIn = (provider) => signInWithRedirect(auth, provider);

export const sendSignInLink = async (email: string) => {
  const { auth, sendSignInLinkToEmail } = await getAuth();
  sendSignInLinkToEmail(auth, email, {
    url: window.location.origin,
    handleCodeInApp: true,
  }).then(() => localStorage.setItem(EMAIL_FOR_SIGN_IN, email));
}

export const getUserLocation = async () => {
  try {
    const response = await fetch(`https://ipinfo.io/json?token=${process.env.NEXT_PUBLIC_IP_INFO_TOKEN}`);
    if (!response.ok) {
      throw new Error("Failed to fetch IP information");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching user location:", error);
    return null;
  }
};