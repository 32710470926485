// eslint-disable-next-line
import { getStorage } from "firebase/storage";
import { app } from "./app";
export const storage = getStorage(app);

// eslint-disable-next-line
export {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage"